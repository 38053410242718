import { isNotNil } from '@shared/utils';

export const sortByTruthiness = <T>(a: T, b: T) =>
  !a === !b ? 0 : !a ? 1 : -1;

export const presortNils = <T>(
  a: T,
  b: T,
  sortFunction?: (a: NonNullable<T>, b: NonNullable<T>) => number,
) =>
  isNotNil(a) && isNotNil(b) ? sortFunction?.(a, b) : sortByTruthiness(a, b);

// The funky 末 is used to sort the shifts that have no start time as last
// as it will be always sorted after time that is written with latin characters.
// We also want rows that have only unavailability components to be sorted last.
export const getNonNulledItemTime = (item?: { begin: string } | null) =>
  item?.begin || '末';

export const sortByNonNulledItemTime = <T extends { begin: string } | null>(
  a?: T,
  b?: T,
) => getNonNulledItemTime(a).localeCompare(getNonNulledItemTime(b));
